.checkmarkCircledIcon11 {
  align-self: stretch;
  position: relative;
  max-height: 100%;
  width: 426px;
}
.aRobosweepEmporium {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.aRobosweepEmporium,
.completionPage,
.completionPageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.completionPage {
  width: 716px;
  height: 311.7px;
  flex-direction: row;
  gap: 50px;
}
.completionPageWrapper {
  position: absolute;
  height: 83.06%;
  width: 86.08%;
  top: 8.27%;
  right: 7.02%;
  bottom: 8.67%;
  left: 6.9%;
  flex-direction: column;
  padding: var(--padding-3xs);
  box-sizing: border-box;
}
.content {
  align-self: stretch;
  flex: 1;
  position: relative;
  background-color: var(--soft-grey);
}
.applypage2,
.applypage21 {
  display: flex;
  flex-direction: column;
}
.applypage21 {
  align-self: stretch;
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
  text-align: center;
  font-size: var(--paragraph-ibm-plex-sans-regular-size);
  color: var(--black);
  font-family: var(--smaller-bodytext);
}
.applypage2 {
  position: relative;
  border-radius: 1.19px;
  background-color: var(--soft-grey);
  width: 100%;
  height: 930px;
  align-items: center;
  justify-content: center;
}
