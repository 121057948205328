
.errorText {
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: red;
  font-family: 'IBM Plex Sans', sans-serif;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  margin: 0;
  padding: 0;
}

.errorText.active {
  opacity: 1;
}