.pexelsPhoto38234881Icon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.pexelsPhoto38234881,
.pexelsYanKrukau88674331 {
  position: absolute;
  height: 46.77%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 53.23%;
  left: 0;
  background-color: var(--white);
  box-shadow: 0 2px 14.74px 2.46px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.pexelsYanKrukau88674331 {
  height: 46.15%;
  width: 99.75%;
  top: 53.85%;
  right: 0.25%;
  bottom: 0;
}
.photos {
  position: absolute;
  top: calc(50% - 200px);
  left: calc(50% - 200.2px);
  width: 203.9px;
  height: 370.5px;
}
.jobPositions,
.jobPositionsWrapper {
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: flex-end;
}
.jobPositions {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  justify-content: flex-end;
  
}
.jobPositionsWrapper {
  flex-direction: column;
  justify-content: flex-start;
}
.completeInfoChild {
  position: relative;
  border-top: 2px solid var(--black);
  box-sizing: border-box;
  width: 90%;
  height: 2px;
  left: 5%;
}
.customerSupportSpecialist,
.providingCustomerService {
  align-self: stretch;
  position: relative;
}
.providingCustomerService {
  font-size: var(--smaller-bodytext-size);
  line-height: 170%;
}
.customerSupport {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4.91px;
}
.responsibleForSelling {
  margin: 0;
}
.completeInfo,
.textbody {
  display: flex;
  flex-direction: column;
}
.textbody {
  width: 100%;
  height: 500px;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 9.82456111907959px 0;
  box-sizing: border-box;
  gap: 26.04px;
  text-align: right;
  font-size: var(--bodytext-size);
  overflow-y: auto;

}
.completeInfo {
  position: relative;
  height: 60%;
  width: 44.45%;
  top: 5.82%;
  right: 2.33%;
  bottom: 15.38%;
  left: 53.21%;
  align-items: center;
  justify-content: space-between;
}
.content {
  align-self: stretch;
  flex: 1;
  position: relative;
  background-color: var(--soft-grey);
  text-align: center;
  font-size: var(--robo-mobile-size);
  color: var(--black);
  font-family: var(--smaller-bodytext);
}
.jobpositionsMobile,
.jobpositionsMobile1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.jobpositionsMobile1 {
  align-self: stretch;
  flex: 1;
  gap: 9.82px;
}
.jobpositionsMobile {
  position: relative;
  background-color: var(--soft-grey);
  width: 100%;
  height: auto;
}

@media screen and (max-width: 429px) {
  .photos {
    transform: scale(0.90);
  }
  

}