.wrapperRectangle3Child {
  align-self: stretch;
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 2px;
  width: 100%;
  height: 100%;
  transform: scale(1.277);
}
.wrapperRectangle3,
.wrapperRectangle3Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapperRectangle3 {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.wrapperRectangle3Wrapper {
  width: 237.8px;
  height: 346.8px;
  flex-direction: column;
}
.contentInner {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 14.245614051818848px 8.8421049118042px;
}
.aboutUs1 {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  display: flex;
  align-items: center;
  height: 58.9px;
}
.aboutUs,
.aboutUsWrapper {
  align-self: stretch;
  height: 72.2px;
  display: flex;
  align-items: center;
}
.aboutUsWrapper {
  border-bottom: 2px solid var(--color-black);
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
}
.aboutUs {
  flex-direction: column;
  justify-content: flex-start;
}
.welcomeToRobosweep {
  margin: 0;
}
.welcomeToRobosweepContainer {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 170%;
  mix-blend-mode: normal;
}
.welcomeToRobosweepEmporiumWrapper {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.content,
.textbody,
.textbodyInner {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.textbodyInner {
  justify-content: flex-start;
  font-size: var(--smaller-bodytext-size);
  color: var(--grey);
}
.content,
.textbody {
  justify-content: center;
  padding: 14.736842155456543px 15px;
  gap: 8.84px;
}
.content {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 28px 0 0;
  gap: 14.25px;
}
.genericpage,
.homepageMobile {
  display: flex;
  flex-direction: column;
}
.genericpage {
  align-self: stretch;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.homepageMobile {
  position: relative;
  background-color: var(--soft-grey);
  width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: var(--robo-mobile-size);
  color: var(--black);
  font-family: var(--smaller-bodytext);
}

@media (max-width: 430px) {

  .content {
    padding: 0;
    gap: 14.25px;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .textbodyInner {
    text-align: center;
    align-items: center;
  }

  .textbody {
    justify-content: center;
    align-items: center;
  }

  .aboutUs1,.aboutUsWrapper, .aboutUs {
    align-items: center;
    justify-content: center;
  }

  .aboutUs{
    padding-bottom: 20px;
  }

  .aboutUsWrapper {
    width: 80%;
    margin: auto;
  }

  .contentInner {
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    padding: 0px 0px;
  }

  .content {
    flex-direction: column;
  }

}