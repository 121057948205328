.pexelsPhoto38234881Icon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.pexelsPhoto38234881,
.pexelsYanKrukau88674331 {
  position: absolute;
  height: 46.78%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 53.22%;
  left: 0;
  background-color: var(--white);
  box-shadow: 0 4px 30px 5px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.pexelsYanKrukau88674331 {
  height: 46.15%;
  width: 99.76%;
  top: 53.85%;
  right: 0.24%;
  bottom: 0;
}
.photos {
  position: absolute;
  top: calc(50% - 377.8px);
  left: calc(50% - 407.5px);
  width: 415px;
  height: 754.2px;
}
.jobPositions,
.jobPositionsWrapper {
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: center;
}
.jobPositions {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  justify-content: center;
}
.jobPositionsWrapper {
  flex-direction: column;
  justify-content: flex-start;
}
.completeInfoChild {
  position: relative;
  border-top: 4px solid var(--black);
  box-sizing: border-box;
  width: 384px;
  height: 4px;
}
.customerSupportSpecialist,
.providingCustomerService {
  align-self: stretch;
  position: relative;
}
.providingCustomerService {
  font-size: var(--bodytext1-size);
  line-height: 170%;
}
.customerSupport {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.responsibleForSelling {
  margin: 0;
}
.completeInfo,
.textbody {
  display: flex;
  flex-direction: column;
}
.textbody {
  width: 380px;
  height: 480px;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xl) 0;
  box-sizing: border-box;
  gap: 53px;
  text-align: right;
  font-size: var(--paragraph-ibm-plex-sans-regular-size);
}
.completeInfo {
  position: absolute;
  height: 78.81%;
  width: 44.44%;
  top: 5.82%;
  right: 2.34%;
  bottom: 15.37%;
  left: 53.22%;
  align-items: center;
  justify-content: space-between;
}
.content {
  align-self: stretch;
  flex: 1;
  position: relative;
  background-color: var(--soft-grey);
  text-align: center;
  font-size: var(--robo-size);
  color: var(--black);
  font-family: var(--smaller-bodytext);
}
.jobpositions,
.jobpositions1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.jobpositions1 {
  align-self: stretch;
  flex: 1;
  gap: var(--gap-xl);
}
.jobpositions {
  position: relative;
  border-radius: 1.19px;
  background-color: var(--soft-grey);
  width: 100%;
  height: 1186px;
}
