.facebookJobsJobFacebookBraChild,
.facebookJobsJobFacebookBraIcon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.facebookJobsJobFacebookBraIcon {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.facebookJobsJobFacebookBraChild {
  background-color: var(--dark-gold);
  mix-blend-mode: lighten;
}
.facebookJobsJobFacebookBra {
  position: absolute;
  top: calc(50% - 156.55px);
  left: calc(50% - 156.55px);
  width: 304px;
  height: 313px;
}
.image {
  position: relative;
  width: 456px;
  height: 165.1px;
}
.content {
  align-self: stretch;
  flex: 1;
  background-color: var(--soft-grey);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding:  0;
}
.applypage,
.applypage1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.applypage1 {
  align-self: stretch;
  flex: 1;
  gap: var(--gap-xl);
}
.applypage {
  position: relative;
  border-radius: 1.19px;
  background-color: var(--soft-grey);
  width: 100%;
  height: 987px;
}
