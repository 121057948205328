.checkmarkCircledIcon11 {
  align-self: stretch;
  position: relative;
  max-height: 100%;
  width: 209.3px;
}
.aRobosweepEmporium {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.aRobosweepEmporium,
.completionPage,
.completionPageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.completionPage {
  width: 351.7px;
  height: 153.1px;
  flex-direction: row;
  gap: 24.56px;
}
.completionPageWrapper {
  position: absolute;
  height: 83.05%;
  width: 86.07%;
  top: 8.28%;
  right: 7.02%;
  bottom: 8.68%;
  left: 6.9%;
  flex-direction: column;
  padding: 4.912280559539795px;
  box-sizing: border-box;
}
.content {
  align-self: stretch;
  flex: 1;
  position: relative;
  background-color: var(--soft-grey);
}
.applypage,
.applypage2Mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.applypage {
  align-self: stretch;
  flex: 1;
  gap: 9.82px;
  text-align: center;
  font-size: var(--bodytext-size);
  color: var(--black);
  font-family: var(--smaller-bodytext);
}
.applypage2Mobile {
  position: relative;
  background-color: var(--soft-grey);
  width: 100%;
  height: 542.3px;
}
