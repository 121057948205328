.applypage,
.applypageMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.applypage {
  align-self: stretch;
  flex: 1;
  gap: 9.82px;
}
.applypageMobile {
  position: relative;
  background-color: var(--soft-grey);
  width: 100%;
  height: 744px;
}
